<template>
  <div class="px-2">
    <div class="flex font-bold">
      <label class="mr-auto">Props</label>
      <font-awesome-icon
        icon="info"
        class="mr-3 cursor-pointer"
        title="Data variable name passed from parent component"
      />
    </div>
    <div
      v-for="(item, idx) of propsList"
      :key="`${item}-${idx}`"
      class="flex"
    >
      <span>{{ item }}</span>
      <span class="cursor-pointer hover:opacity-80 opacity-30 ml-11"
        @click="remove(idx)"
      >
        <font-awesome-icon icon="minus" />
      </span>
    </div>
    <div v-if="propsList.length === 0" class="opacity-50">
      No props
    </div>
    <div class="flex pr-2 mt-2">
      <input v-model="newName" type="text" class="min-w-0" placeholder="New prop" />
      <span class="ml-2 cursor-pointer" :class="{
          'opacity-30': !newName,
          'opacity-80': !!newName
        }"
        @click="add"
      >
        <font-awesome-icon icon="plus" />
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PropsEdit',
  props: {
    propsList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      newName: ''
    }
  },
  methods: {
    add () {
      this.$emit('set', this.propsList.concat(this.newName))
      this.newName = ''
    },
    remove (idx) {
      let p = [ ...this.propsList ]
      p.splice(idx, 1)
      this.$emit('set', p)
    }
  }
}
</script>
