<template>
  <div class="flex flex-col">
    <div class="font-bold">
      Components
    </div>
    <div
      v-for="comp of orderedComponents"
      :key="comp._id"
      class="cursor-pointer"
      :class="{
        'font-semibold bg-white': currentComponent && currentComponent._id === comp._id
      }"
      @click="select(comp._id)"
    >
      <div class="flex justify-between pr-2 items-center">
        {{ comp.name }}
        <font-awesome-icon v-if="comp.isUsed" icon="check" class="opacity-20 ml-auto mr-2" title="Used"/>
        <button v-if="!isComponentView && comp.name !== 'app'" @click="$emit('remove', comp._id)">
          <font-awesome-icon icon="minus" class="opacity-10 hover:opacity-80" />
        </button>
      </div>
    </div>
    <button
      class="w-full flex items-center opacity-60 hover:opacity-100"
      @click="$emit('openAdd', null)"
    >
      <span class="mr-2">Add component</span>
      <font-awesome-icon icon="plus" class="opacity-50" />
    </button>
  </div>
</template>
<script>

export default {
  name: 'ComponentList',
  props: {
    components: {
      type: Array,
      default: () => ([])
    },
    currentComponent: {
      type: Object,
      default: () => -1
    },
    isComponentView: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    orderedComponents () {
      const allDeps = this.components.reduce((deps, comp) => deps.concat(comp.deps), [])
      console.log('allDeps: ', allDeps);
      return this.components.map(comp => {
        return {
          ...comp,
          isUsed: allDeps.includes(comp._id)
        }
      }).sort((cp1, cp2) => {
        return cp2.isUsed - cp1.isUsed
      }).filter(cp => cp.name !== 'app')
    }
  },
  data () {
    return {
      newName: ''
    }
  },
  methods: {
    select (idx) {
      this.$emit('select:component', idx)
    },
  }
}
</script>
