<template>
  <div :style="nodeMargin">
    <div
      :class="{
        'flex pr-2': true,
        'bg-white': node.selected
      }"
      @click="$emit('select:component', node._id)"
    >
      <label :class="{
        'cursor-pointer': true,
        'font-bold': node.selected
      }">
        {{ node.name }}
      </label>
      <span
        class="ml-auto cursor-pointer opacity-10 hover:opacity-80"
        title="add dependency"
        @click.stop="$emit('openAdd', node._id)"
      >
        <font-awesome-icon icon="plus" />
      </span>
      <span
        v-if="node.name !== 'app'"
        class="ml-2 cursor-pointer opacity-10 hover:opacity-80" title="remove this component"
        @click.stop="$emit('remove:dep', { nodeId: node._id, parentId: node.pid })"
      >
        <font-awesome-icon icon="minus" />
      </span>
    </div>
    <div v-if="hasChildren">
      <TreeNode
        v-for="child in node.children"
        :key="child.id"
        :node="child"
        :spacing="spacing + 10"
        v-bind="$attrs"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'TreeNode',
  props: {
    node: {
      type: Object,
      required: true
    },
    spacing: {
      type: Number,
      default: 0
    }
  },
  computed: {
    nodeMargin() {
      return {
        'margin-left': `${this.spacing}px`
      }
    },
    hasChildren() {
      const { children } = this.node
      return children && children.length > 0
    }
  }
}
</script>
