<template>
  <div class="p-2 flex flex-col">
    <label class="mb-1" v-if="currentComponent.name !== 'app'">
      <input type="checkbox" v-model="is_global" :disabled="isComponentView"  />
      This is global component
    </label>
    <div class="flex">
      <label class="mb-0 w-1/3">name</label>
      <input type="text" class="w-2/3" v-model="name" :disabled="is_global" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'ComponentConfig',
  props: {
    currentComponent: {
      type: Object,
      default: () => ({})
    },
    settings: {
      type: Object,
      required: true
    },
    isComponentView: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      is_global: false,
      name: ''
    }
  },
  watch: {
    settings: {
      handler (v) {
        Object.keys(v).forEach(field => {
          this[field] = v[field]
        })
      },
      immediate: true
    },
    is_global (v) {
      this.$emit('settings:change', { is_global: v })
    },
    name (v) {
      this.$emit('settings:change', { name: v })
    }
  }
}
</script>
