<template>
  <div class="">
    <PageHeader />
    <div class="px-3">
      <div class='flex items-end gap-3'>
        <div class="text-xl">
          Sites
        </div>
        <label>
          <input type='checkbox' v-model='archivedOnly' />
          Archived
        </label>
      </div>
      <div class="ml-3">
        <div
          v-for="site of sites"
          :key="site._id"
        >
          <div class="w-56 inline-block">
            <label class="text-lg">{{ site.title || site.uri }}</label>
            <small class="ml-1">({{ site.uri }})</small>
          </div>
          <label v-if="!site.is_archived" class="cursor-pointer ml-5" @click="goToSite(site.uri)">
            <font-awesome-icon icon="edit" title="Edit"/>
          </label>
          <label v-if="!site.is_archived" class="cursor-pointer ml-5">
            <input
              disabled
              type="text"
              class="w-32 mr-2 bg-white"
              :id="`querystring-${site._id}`"
              :defaultValue="site.defaultQuery"
            />
            <font-awesome-icon icon="eye" @click="openSitePage(site)" title="View"/>
          </label>
          <label v-if="!site.is_archived" class="cursor-pointer ml-2" title="Duplicate">
            <font-awesome-icon icon="copy" @click="dupSite(site)" title="Copy Site"/>
          </label>
          <label v-if="!site.is_archived" class="cursor-pointer ml-2" title="Archive">
            <font-awesome-icon icon="archive" @click="archive(site)" title="Archive Site"/>
          </label>
          <label v-if="site.is_archived" class="cursor-pointer ml-2" title="Unarchive">
            <font-awesome-icon icon="backward" @click="unArchive(site)" title="Unarchive Site"/>
          </label>
        </div>
        <div v-if="loading" class="bg-gray-500 bg-opacity-25 fixed top-0 left-0 w-screen h-screen flex items-center justify-center">
          Loading ...
        </div>
        <div v-if="copying" class="bg-gray-500 bg-opacity-25 fixed top-0 left-0 w-screen h-screen flex items-center justify-center">
          Copying ...
        </div>
        <div v-else-if="sites.length === 0" class="text-lg">
          {{ archivedOnly ? "No archived sites" : "No sites yet" }}
        </div>
      </div>
      <div class="flex mt-5">
        <input class="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" v-model="siteName" placeholder="Site URI" />
        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2" @click="goToSite(siteName)">Go</button>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from './components/PageHeader'
import * as siteService from '../service/site'
import { viewer_url } from '../constants'

export default {
  name: 'SitesList',
  components: {
    PageHeader
  },
  data () {
    return {
      loading: false,
      siteName: '',
      sites: [],
      copying: false,
      archivedOnly: false
    }
  },
  mounted () {
    this.loadData()
  },
  watch: {
    archivedOnly () {
      this.loadData()
    }
  },
  methods: {
    async loadData () {
      this.loading = true
      try {
        this.sites = await siteService.getSites(this.archivedOnly)
      } catch (err) {
        console.log('err: ', err);
      }
      this.loading = false
    },
    goToSite (name) {
      if (!name) { return }
      window.location.href = `/site-edit/${name}`
    },
    openSitePage (site) {
      const query = document.querySelector(`#querystring-${site._id}`).value
      const url = `${viewer_url}/${site.uri}?${query}`
      window.open(url)
    },
    async archive (site) {
      const confirm = window.confirm(`Are you sure want to archive ${site.uri}?`)
      if (!confirm) { return }
      await siteService.archiveSite(site.uri)
      this.loadData()
    },
    async unArchive (site) {
      await siteService.unArchiveSite(site.uri)
      this.loadData()
    },
    async dupSite (site) {
      this.copying = true
      const newSiteUri = window.prompt('New Site Uri', `${site.uri}-copy`)
      try {
        await siteService.duplicateSite(site.uri, newSiteUri)
        this.loadData()
      } catch (err) {
        this.$notify({
          type: "warn",
          text: 'URI is already used. please use new uri',
        });
      }
      this.copying = false
    }
  }
}
</script>
