<template>
  <div class="w-full h-full flex flex-col">
    <div class="flex border-t border-b">
      <input ref="urlRef" type="text" :defaultValue="baseurl" class="flex-grow px-2" />
      <span @click="reloadIframe" class="mx-2 cursor-pointer opacity-30 hover:opacity-80">
        <font-awesome-icon icon="redo" />
      </span>
    </div>
    <iframe :key="key" :src="url" class="w-full h-full border-none bg-gray-50" />
  </div>
</template>
<script>
export default {
  name: 'Preview',
  props: {
    baseurl: {
      type: String,
      default: () => ''
    }
  },
  watch: {
    baseurl (v) {
      this.url = v
    }
  },
  data () {
    return {
      url: '',
      key: 1
    }
  },
  methods: {
    reloadIframe () {
      this.url = this.$refs.urlRef.value
      this.key ++
    }
  }
}
</script>
